import axios from "axios";
import toasted from "@/core/plugins/vue-toasted";

const state = {
  profileData: null,
  clientsData: null,
  industries: [],
  dealOwners: [],
  technologies: [],
  roles: [],
  matchingSkills: [],
  nominationHistory: [],
  nominationList: [],
  stackholderList: [],
  selectedTalents: [],
  talentInterviewStatus: [],
  requiredResources: [],
  selectedTalentsMeta: [],
  clientNominationsList: [],
  clientNominationsListMeta: null,
  profileDataProject: [],
};

const getters = {
  getProfileData(state) {
    return state.profileData;
  },
  getClientsData(state) {
    return state.clientsData?.data;
  },
  getClientsMeta(state) {
    return state.clientsData?.meta;
  },
  getIndustries(state) {
    return state.industries;
  },
  getDealOwners(state) {
    return state.dealOwners;
  },
  getTechnologies(state) {
    return state.technologies;
  },
  getRoles(state) {
    return state.roles;
  },
  getMatchingSkills(state) {
    return state.matchingSkills;
  },
  getNominationHistory(state) {
    return state.nominationHistory.reverse();
  },
  getNominationList(state) {
    return state.nominationList;
  },
  getStackholderList(state) {
    return state.stackholderList;
  },
  getSelectedTalentsList(state) {
    return state.selectedTalents;
  },
  getProjectRequiredResources(state) {
    return state.requiredResources;
  },
  getTalentInterviewStatus(state) {
    return state.talentInterviewStatus;
  },
  getSelectedTalentsMeta(state) {
    return state.selectedTalentsMeta;
  },
  getClientNominationsList(state) {
    return state.clientNominationsList;
  },
  getClientNominationsListMeta(state) {
    return state.clientNominationsListMeta;
  },
  getProfileDataProject(state) {
    return state.profileDataProject;
  },
  getClientOnlinePayments(state) {
    return state.clientOnlinePayments;
  },
};

const mutations = {
  setProfileData(state, payload) {
    state.profileData = payload;
  },
  setProfileDataProject(state, payload) {
    state.profileDataProject = payload;
  },
  setClientsData(state, payload) {
    state.clientsData = payload;
  },
  setIndustries(state, payload) {
    state.industries = payload;
  },
  setDealOwners(state, payload) {
    state.dealOwners = payload;
  },
  setTechnologies(state, payload) {
    state.technologies = payload;
  },
  setRoles(state, payload) {
    state.roles = payload;
  },
  setMatchingSkills(state, payload) {
    state.matchingSkills = payload;
  },
  setNominationHistory(state, payload) {
    state.nominationHistory = payload;
  },
  setNominationList(state, payload) {
    state.nominationList = payload;
  },
  setStackholderList(state, payload) {
    state.stackholderList = payload;
  },
  setSelectedTalentsList(state, payload) {
    state.selectedTalents = payload;
    state.selectedTalents.forEach((talent) => {
      talent.statusChanged = false;
      talent.clickupTaskID = null;
    });
  },
  setProjectRequiredResources(state, payload) {
    state.requiredResources = payload;
  },
  setTalentInterviewStatus(state, payload) {
    state.talentInterviewStatus = payload;
  },
  setSelectedTalentsMeta(state, payload) {
    state.selectedTalentsMeta = payload;
  },
  setClientNominationsList(state, payload) {
    state.clientNominationsList = payload;
  },
  setClientNominationsListMeta(state, payload) {
    state.clientNominationsListMeta = payload;
  },
  setClientOnlinePayments(state, payload) {
    state.clientOnlinePayments = payload;
  },
};

const actions = {
  getProfileData({ commit }, payload) {
    return new Promise((resolve) => {
      commit("setProfileData", null);
      axios.get(`users/${payload}`).then((res) => {
        commit("setProfileData", res.data.data);
        resolve("done");
      });
    });
  },
  getProfileDataProject({ commit }, payload) {
    return new Promise((resolve) => {
      commit("setProfileData", null);
      function generateuuid() {
        return Math.floor(Math.random() * 1000000);
      }
      axios.get(`clients/projects/${payload}`).then((res) => {
        let payload = {
          ...res.data.data,
          signup_requests: res.data.data.signup_requests.map((r) => ({
            ...r,
            uuid: generateuuid(),
          })),
        };
        commit("setProfileDataProject", payload);
        resolve("done");
      });
    });
  },
  getProfileDataWithOutLoading({ commit }, payload) {
    return new Promise((resolve) => {
      axios.get(`users/${payload}`).then((res) => {
        commit("setProfileData", res.data.data);
        resolve(res.data.data);
      });
    });
  },
  getProfileDataProjectWithOutLoading({ commit }, payload) {
    return new Promise((resolve) => {
      axios.get(`clients/projects/${payload}`).then((res) => {
        commit("setProfileDataProject", res.data.data);
        resolve("done");
      });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getRolesTalentsNomination({ commit }, payload) {
    return new Promise((resolve) => {
      axios
        .get(`/client/technology/talents`, {
          params: {
            ...payload,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  getNominationList({ commit }, payload) {
    let { clinet_id } = payload;
    return new Promise((resolve) => {
      axios.get(`/client/interested/${clinet_id}/talents`).then((res) => {
        commit("setNominationList", res.data.data);
        resolve("done");
      });
    });
  },
  getNominationListProject({ commit }, payload) {
    let { project_id } = payload;
    return new Promise((resolve) => {
      axios
        .get(`/clients/projects/${project_id}/interested-talents`)
        .then((res) => {
          commit("setNominationList", res.data.data);
          resolve("done");
        });
    });
  },
  setNominationHistory({ commit }, payload) {
    let { clinet_id } = payload;
    return new Promise((resolve) => {
      axios.get(`/client/nomination/${clinet_id}/history`).then((res) => {
        commit("setNominationHistory", res.data.data);
        resolve("done");
      });
    });
  },
  setNominationHistoryProject({ commit }, payload) {
    let { project_id } = payload;
    return new Promise((resolve) => {
      axios
        .get(`/clients/projects/${project_id}/nomination-history`)
        .then((res) => {
          commit("setNominationHistory", res.data.data);
          resolve("done");
        });
    });
  },
  nominateTalantProject({ dispatch }, payload) {
    let { body, project_id } = payload;
    return new Promise((resolve) => {
      axios
        .post(`/clients/projects/${project_id}/nominate-talents`, body)
        .then(() => {
          toasted.show("Talents Notified Successfully", {
            type: "success",
            icon: "check_circle",
          });
          dispatch("setNominationHistoryProject", payload);
          resolve("done");
        });
    });
  },
  nominateTalant({ dispatch }, payload) {
    let { body, clinet_id } = payload;
    return new Promise((resolve) => {
      axios.post(`/nominate/${clinet_id}/talents`, body).then(() => {
        toasted.show("Talents Notified Successfully", {
          type: "success",
          icon: "check_circle",
        });
        dispatch("setNominationHistory", payload);
        resolve("done");
      });
    });
  },
  getMatchingSkills({ commit }, payload) {
    let { role_id, clinet_id } = payload;
    return new Promise((resolve) => {
      axios
        .get(`/client/projects/matching/${role_id}/skills/${clinet_id}`)
        .then((res) => {
          commit("setMatchingSkills", res.data.data);
          resolve("done");
        });
    });
  },
  getMatchingSkillsProject({ commit }, payload) {
    let { role_id, project_id } = payload;
    return new Promise((resolve) => {
      axios
        .get(
          `/clients/projects/${project_id}/matching/roles/${role_id}/skills/`
        )
        .then((res) => {
          commit("setMatchingSkills", res.data.data);
          resolve("done");
        });
    });
  },
  getRoles({ commit }, payload) {
    let { clinet_id } = payload;
    return new Promise((resolve) => {
      commit("setRoles", []);
      axios.get(`/client/projects/matching/roles/${clinet_id}`).then((res) => {
        commit("setRoles", res.data.data);
        resolve("done");
      });
    });
  },
  getFilteredRoles({ commit }, payload) {
    let { client_id, project_id } = payload;
    return new Promise((resolve) => {
      commit("setRoles", []);
      axios
        .get(
          `/client/projects/matching/roles/${client_id}?project_id=${project_id}&filtered=false`
        )
        .then((res) => {
          commit("setRoles", res.data.data);
          resolve("done");
        });
    });
  },
  getRolesProject({ commit }, payload) {
    let { project_id, filtered } = payload;
    return new Promise((resolve) => {
      commit("setRoles", []);
      axios
        .get(
          `/clients/projects/${project_id}/matching/roles${
            filtered !== undefined ? `?filtered=${filtered}` : ""
          }`
        )
        .then((res) => {
          commit("setRoles", res.data.data);
          resolve("done");
        });
    });
  },
  getClientsData({ commit }, payload) {
    console.log("payload", payload);
    let { page = 1, perPage = 8 } = payload;
    page === 1 && commit("setClientsData", null);
    return new Promise((resolve) => {
      axios
        .get("users/clients", {
          params: {
            page,
            perPage,
            ...payload,
          },
        })
        .then((res) => {
          commit("setClientsData", res.data);
          resolve("done");
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  deleteSelectedClients({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/users/clients`, {
          data: {
            ...payload,
          },
        })
        .then(() => {
          resolve("done");
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  setClientsDataProject({ commit }, payload) {
    let {
      about,
      services,
      technologies,
      client_id,
      details,
      project_id,
      notes_comments,
      signup_requests,
    } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(
          `clients/${client_id}/projects${
            payload.project_id ? `?project_id=${payload.project_id}` : ""
          }`,
          {
            ...about,
            services,
            ...technologies,
            ...details,
            project_id,
            notes_comments,
            signup_requests,
          }
        )
        .then((res) => {
          toasted.show("Client request Updated Successfully", {
            type: "success",
            icon: "check_circle",
          });
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  setClientsData({ commit }, payload) {
    let { about, services, technologies, details, client_id, notes_comments } =
      payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`client/projects/about/${client_id}`, about)
        .then(() => {
          axios
            .post(`client/projects/services/${client_id}`, { services })
            .then(() => {
              axios
                .post(`client/projects/technologies/${client_id}`, technologies)
                .then(() => {
                  axios
                    .post(`client/projects/details/${client_id}`, details)
                    .then(() => {
                      axios
                        .post(`client/projects/notes/${client_id}`, {
                          notes_comments,
                        })
                        .then(() => {
                          toasted.show("Client request Updated Successfully", {
                            type: "success",
                            icon: "check_circle",
                          });
                          resolve("done");
                        })
                        .catch((error) => reject(error));
                    })
                    .catch((error) => reject(error));
                })
                .catch((error) => reject(error));
            })
            .catch((error) => reject(error));
        })
        .catch((error) => reject(error));
    });
  },
  getIndustries({ commit }) {
    return new Promise((resolve) => {
      axios.get(`/staticContent/projectTypes`).then((res) => {
        commit("setIndustries", res.data.data);
        resolve(res.data.data);
      });
    });
  },
  getDealOwners({ commit }) {
    return new Promise((resolve) => {
      axios.get(`/staticContent/dealOwners`).then((res) => {
        commit("setDealOwners", res.data.data);
        resolve(res.data.data);
      });
    });
  },
  getTechnologies({ commit, getters }) {
    return new Promise((resolve) => {
      axios.get(`/staticContent/clientTechnologies`).then((res) => {
        commit(
          "setTechnologies",
          res.data.data.map((tech) => {
            return { ...tech, other: false };
          })
        );

        axios
          .get(`/staticContent/clientTechnologies?others=1`)
          .then((response) => {
            commit(
              "setTechnologies",
              [
                ...getters.getTechnologies,
                ...response.data.data.map((tech) => {
                  return { ...tech, other: true };
                }),
              ].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
            );
          });

        resolve(res.data.data);
      });
    });
  },
  addStackholder({ dispatch }, payload) {
    const { project_id, data } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`/clients/projects/${project_id}/stakeholders`, data)
        .then((res) => {
          toasted.show("Stackholder Added  Successfully", {
            type: "success",
            icon: "check_circle",
          });
          dispatch("getAllStackHolders", payload);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  editStackholder({ dispatch }, payload) {
    const { project_id, stakeholder_id, data } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/clients/projects/${project_id}/stakeholders/${stakeholder_id}`,
          data
        )
        .then((res) => {
          toasted.show("Stackholder Edited Successfully", {
            type: "success",
            icon: "check_circle",
          });
          dispatch("getAllStackHolders", payload);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  deleteStackHolders({ dispatch }, payload) {
    const { project_id, stakeholder_id } = payload;
    return new Promise((resolve) => {
      axios
        .delete(
          `/clients/projects/${project_id}/stakeholders/${stakeholder_id}`
        )
        .then((res) => {
          dispatch("getAllStackHolders", payload);
          resolve(res.data.data);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getAllStackHolders({ commit }, payload) {
    const { project_id } = payload;
    return new Promise((resolve) => {
      axios.get(`/clients/projects/${project_id}/stakeholders`).then((res) => {
        commit("setStackholderList", res.data.data);
        resolve(res.data.data);
      });
    });
  },
  getSelectedTalentsList({ commit }, payload) {
    const { project_id, talentsQuery } = payload;

    let { page = 1, perPage = 8 } = talentsQuery;
    return new Promise((resolve) => {
      axios
        .get(`/clients/projects/${project_id}/talent-selection`, {
          params: {
            page,
            perPage,
          },
        })
        .then((res) => {
          commit("setSelectedTalentsList", res.data.data);
          commit("setSelectedTalentsMeta", res.data.meta);
          resolve(res.data.data);
        });
    });
  },
  setSelectedInterviewSlot(_, payload) {
    const { selection_id, interview_slot_id } = payload;

    return new Promise((resolve) => {
      axios
        .put(`/talent-selections/${selection_id}`, {
          slot_id: interview_slot_id,
          status: "scheduled",
        })
        .then((res) => {
          resolve(res);
        });
    });
  },
  getProjectRequiredResources({ commit }, payload) {
    const { project_id } = payload;
    return new Promise((resolve) => {
      axios
        .get(`/clients/projects/${project_id}/required-resource`)
        .then((res) => {
          commit("setProjectRequiredResources", res.data.data);
          resolve(res.data.data);
        });
    });
  },
  createRequiredResource({ dispatch }, payload) {
    const { selection } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`/talent-selection/${selection}/required-resource`)
        .then(() => {
          toasted.show("Resource Added Successfully", {
            type: "success",
            icon: "check_circle",
          });
          dispatch("getProjectRequiredResources", payload);
          dispatch("getSelectedTalentsList", payload);
          resolve();
        })
        .catch((error) => reject(error));
    });
  },
  resyncMatchTalentWithRequieredResources(_, payload) {
    const { selection } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/talent-selection/${selection}/match`)
        .then(() => {
          toasted.show("Auto-matching is done successfully", {
            type: "success",
            icon: "check_circle",
          });
          resolve();
        })
        .catch((error) => reject(error));
    });
  },
  updateTalentStatus(_, payload) {
    const { selection, status, required_resource_id } = payload;
    return new Promise((resolve) => {
      axios
        .patch(`/talent-selection/${selection}`, {
          status,
          required_resource_id,
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getTalentStatuses({ commit }) {
    return new Promise((resolve) => {
      axios
        .get(`/staticContent/selectionStatuses`)
        .then((res) => {
          commit("setTalentInterviewStatus", res.data.data);
          resolve();
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  getClientNominationsList({ commit }, payload) {
    const { project_id, current_page, per_page } = payload;
    return new Promise((resolve) => {
      axios
        .get(
          `/clients/projects/${project_id}/talent-nominations?page=${current_page}&per_page=${per_page}`
        )
        .then((res) => {
          commit("setClientNominationsList", res.data.data);
          commit("setClientNominationsListMeta", res.data.meta);
          resolve(res.data.data);
        });
    });
  },
  updateOnlinePayments(store, payload) {
    const { client_id, online_payments } = payload;
    return new Promise((resolve) => {
      axios
        .post(`/clients/${client_id}`, {
          online_payments,
        })
        .then((res) => {
          resolve(res.data.data);
        });
    });
  },
  updateRenewalType(store, payload) {
    const { project_id, renewalType } = payload;
    return new Promise((resolve) => {
      axios
        .patch(`/clients/projects/${project_id}`, {
          renewal_type: renewalType,
        })
        .then((res) => {
          resolve(res.data.data);
        });
    });
  },
  updateRequestInterview(store, payload) {
    const { project_id, interview_allowed } = payload;
    return new Promise((resolve, reject) => {
      axios
        .patch(`/clients/projects/${project_id}`, {
          interview_allowed,
        })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
